.react-datepicker-wrapper {
  display: block;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background: #63af39;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: #63af3988;
}

html {
  font-size: 13px;
}

.react-datepicker-popper {
  z-index: 26 !important;
}