.xero-btn {
  background-color: #0c7da1;
}

.partner-btn {
  margin-top: 0 !important;
  height: 60px;
}

.xero-login {
  background-color: #0c7da1;
  border-color: #33c2ea;
  color: white;
  outline: none !important;
}

.google-login {
  background-color: #4285f4;
  border-color: #33c2ea;
  color: white;
  outline: none !important;
  padding-top: 15px !important;
}

.integration-btn,
.xero-btn {
  margin: 10px;
  outline: none;
  border: none;
  color: white;
}

.xero-btn:hover,
.xero-login:hover {
  background-color: #33c2ea;
  color: white;
}

.integration-btn {
  padding: 16px 32px;
  font-size: 14px;
}

.link {
  color: #337ab7;
  cursor: pointer;
}

.domain-bar {
  background-color: #ff0000;
  position: fixed;
  bottom: 0;
  line-height: 35px;
  text-align: center;
  width: -webkit-fill-available;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  padding: 0 15px;
  z-index: 10;
}
